export const constants = {
    // apiBaseUrl: `${window.location.origin?.replace('3000','8080')}/api`,
    apiBaseUrl: `https://residentiedirtya.student.utwente.nl/api`,
    ledApiBaseUrl: `https://dirtyled.student.utwente.nl`,
    localstorageJwtKey: 'token',
    localStorageSettingsKey: 'settings',
    roles: {
        admin: 'ADMIN',
        user: 'USER',
        feut: 'FEUT'
    },
    imageCacheName: 'dirty-a-image-cache',
};
